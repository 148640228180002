<template>
  <div
    to="#top"
    class="px-6 pb-10 pt-[40px] md:px-[90px] md:pb-14 md:pt-[50px]"
  >
    <div class="md:flex md:gap-[64px]">
      <!-- Payment content -->
      <div class="mb-10 w-full md:mb-0">
        <!-- Header -->
        <div class="">
          <div class="flex">
            <h2 class="font-Kontesa text-[32px] font-bold text-[#16114F]">
              PAYMENT METHOD
            </h2>
          </div>

          <!-- Divider -->
          <div class="mb-5 mt-4 border border-dashed border-navy/30" />
        </div>

        <!-- User data -->
        <div class="grid gap-5">
          <div class="two-col">
            <TextInput label="First Name" placeholder="Jane" />
            <TextInput label="Last Name" placeholder="Janson" />
          </div>

          <div class="two-col">
            <TextInput
              v-model="email"
              label="Email"
              placeholder="jane@gmail.com"
            />
            <TextInput label="Postal Code" placeholder="32000" />
          </div>
        </div>

        <!-- Divider -->
        <div class="my-8 border border-dashed border-navy/30" />

        <!-- Payment stripe data -->
        <div class="grid gap-5">
          <TextInput label="Card Number" placeholder="1234 1234 1234 1234" />

          <div class="two-col">
            <TextInput label="Validity Period" placeholder="11 / 25" />
            <TextInput label="CVC" placeholder="816" />
          </div>

          <TextInput label="Country" placeholder="Croatia" />

          <div
            class="h-[22px]font-Aeonik text-xs font-normal leading-[14.4px] -tracking-[0.01em] text-[#16114F]"
          >
            <span class="opacity-60"
              >By specifying the card details, you hereby authorize Dollar
              Donation Club to debit the amount of this and future payments from
              your card according to the company's terms. Unsubscribe at any
              time.</span
            >
          </div>
        </div>
      </div>

      <!-- Payment summary -->
      <div
        class="w-full rounded-[10px] border border-[#16114F] bg-white pt-[25px] shadow-[2px_2px_0px_0px_#16114F] md:w-auto md:min-w-[334px] md:border-0 md:bg-transparent md:shadow-none"
      >
        <h2
          class="mb-4 pl-5 font-Kontesa text-[32px] font-bold text-[#16114F] md:pl-0"
        >
          SUMMARY
        </h2>

        <!-- Summary box -->
        <div
          class="border-with-shadow grid gap-2 rounded-[10px] bg-white p-6 text-navy"
        >
          <div class="flex">
            Your donation

            <span class="ml-auto">$1.00</span>
          </div>
          <div class="flex">
            Tip

            <span class="ml-auto">$1.00</span>
          </div>
          <div class="flex">
            Visa/Mastercard fee

            <span class="ml-auto">$1.00</span>
          </div>
          <!-- Divider -->
          <div class="border border-dashed border-navy/30" />

          <div class="flex font-bold">
            Total

            <span class="ml-auto">$1.00</span>
          </div>
        </div>

        <!-- Action buttons -->
        <div class="mt-5 hidden gap-3 md:flex">
          <Button
            variant="icon"
            class="min-h-[50px] min-w-[50px] flex-shrink-0"
            @click="goBack"
          >
            <img src="/images/join/email/arrow.png" class="w-[14px]" />
          </Button>
          <button
            variant="primary-join"
            class="grow rounded-full border-2 border-[#16114F] !bg-[#5090f3] font-Aeonik font-bold text-white shadow-[0px_2px_0px_0px_#16114F] hover:!bg-yellow hover:!text-[#16114F] active:!bg-white"
            @click="$emit('next')"
          >
            SUBSCRIBE
          </button>
        </div>
      </div>
    </div>

    <div class="mt-5 flex gap-3 md:hidden">
      <Button
        variant="icon"
        class="min-h-[50px] min-w-[50px] flex-shrink-0"
        @click="goBack"
      >
        <img src="/images/join/email/arrow.png" class="w-[14px]" />
      </Button>
      <button
        variant="primary-join"
        class="grow rounded-full border-2 border-[#16114F] !bg-[#5090f3] font-Aeonik font-bold text-white shadow-[0px_2px_0px_0px_#16114F] hover:!bg-yellow hover:!text-[#16114F] active:!bg-white"
        @click="$emit('next')"
      >
        SUBSCRIBE
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { goBack } = useJoinStore();

const email = ref("");

onMounted(() => {
  const { data } = useJoinStore();
  if (data.email) {
    email.value = data.email;
  }
});
</script>

<style scoped lang="scss">
.two-col {
  @apply grid gap-5 md:grid-cols-2 md:gap-3;
}
</style>
