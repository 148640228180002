<template>
  <div
    class="rounded-lg border-[1.4px] border-[#16114F] bg-white px-2 pt-3 md:px-0 md:pt-0"
  >
    <div
      :class="`flex flex-col rounded-md border-[1.4px] border-[#16114F] bg-white md:flex-row md:space-x-3 md:border-none md:px-3 md:pt-3`"
    >
      <div>
        <div
          :class="`relative flex justify-between rounded-t-md px-3 pb-4 pt-4 md:grid md:h-[144px] md:w-[143px] md:place-content-center md:rounded-md md:border-[1.4px] md:border-[#16114F] md:pt-0 ${bgColor}`"
        >
          <img
            :src="campaignIcon"
            class="z-10 h-[38px] w-[38px] md:absolute md:left-1/2 md:top-1/2 md:h-[111px] md:w-[111px] md:-translate-x-1/2 md:-translate-y-1/2"
          />
          <span
            class="text-shadow relative z-10 font-Kontesa text-4xl font-extrabold leading-8 -tracking-[0.03] text-white md:text-[38px]"
          >
            X{{ Math.ceil(modelValue[0] * impactRatio!) }}
          </span>
        </div>
        <Button
          class="my-[9px] hidden w-full !bg-white !py-[9px] !text-lg !leading-[18px] shadow-[0px_2px_0px_0px_rgb(22,17,79)] active:!bg-navy md:block"
          @click="playVideo"
        >
          Play video
        </Button>
      </div>
      <div
        :class="`md:flex md:w-full md:flex-col ${bgColor} rounded-b-md md:bg-white`"
      >
        <div
          :class="`rounded-md border-[1.4px] border-[#16114F] px-3 py-4 md:flex md:w-full md:flex-col ${bgColor}`"
        >
          <div class="">
            <div class="flex items-center justify-between">
              <span
                class="font-Aeonik text-[15px] font-bold uppercase leading-[18px] -tracking-[0.03] text-[#16114F]"
              >
                {{ projectTitle }}
              </span>

              <div class="relative flex space-x-3">
                <CampaignSummaryTooltipTrigger
                  class="md:h-10 md:w-10"
                  :campaign-id="campaignId"
                />
                <JoinStepSubscriptionSelectorDialog
                  :open="openCancellationModal"
                  :theme="theme"
                >
                  <button
                    class="border-with-shadow h-8 w-8 rounded-full bg-white font-bold md:h-10 md:w-10"
                    @click="openSubscriptionCancellationModal"
                  >
                    —
                  </button>
                </JoinStepSubscriptionSelectorDialog>
              </div>
            </div>

            <JoinStepSubscriptionSelectorItemInputs
              v-model="modelValue[0]"
              :min
              :max
              :bg-color="bgColor"
            />
          </div>
        </div>

        <div
          :class="`rounded-b-md py-3 text-center font-Aeonik text-xs font-bold leading-[14px] -tracking-[0.01em] text-[#16114F] md:relative md:bottom-1 md:z-0 md:border-[1.4px] md:border-[#16114F] md:text-lg md:leading-[25px] md:-tracking-[0.03em] ${bgColor}`"
        >
          <span
            href="#"
            class="cursor-pointer"
            :class="monthlyPayment || 'text-[#8A88A7]'"
            @click="monthlyPayment = true"
            >Monthly
            <span :class="!monthlyPayment ? 'hidden' : 'text-[#8A88A7]'"
              >/</span
            ></span
          >
          <span
            href="#"
            class="cursor-pointer"
            :class="!monthlyPayment || 'text-[#8A88A7]'"
            @click="monthlyPayment = false"
          >
            <span :class="monthlyPayment ? 'hidden' : 'text-[#8A88A7]'">/</span>
            One time
          </span>
        </div>
      </div>
    </div>

    <Button
      class="my-[9px] w-full !bg-white !py-[9px] !text-lg !leading-[18px] shadow-[0px_2px_0px_0px_rgb(22,17,79)] active:!bg-navy md:hidden"
      @click="playVideo"
    >
      Play video
    </Button>

    <VideoPlayerModal v-if="videoPlayerId" :id="videoPlayerId" ref="video" />
  </div>
</template>

<script lang="ts" setup>
import type { Campaign } from "~/lib/campaigns";
import VideoPlayerModal from "~/components/VideoPlayer/Modal.vue";

interface SliderProps {
  min: number;
  max: number;
  bgColor?: string;
  projectTitle?: string;
  campaignIcon?: string;
  campaignId: Campaign;
  impactRatio?: number;
  videoPlayerId?: string;
  imageSrc?: string;
  theme?: string;
}

defineProps<SliderProps>();

const modelValue = defineModel<number[]>("modelValue", { default: [10] });

const monthlyPayment = ref(true);
const openCancellationModal = ref(false);

const openSubscriptionCancellationModal = () => {
  openCancellationModal.value = !openCancellationModal.value;
};

onMounted(() => {
  const { counter, pause } = useInterval(50, { controls: true });
  // Save initial value as a stopping signal
  const initialModelValue = modelValue.value[0];
  // Set the starting value
  modelValue.value[0] = 0;
  // Have the counter go until it reaches the initial value and then pause the counter
  watch(counter, () => {
    if (modelValue.value[0] < initialModelValue) {
      modelValue.value[0]++;
    } else {
      pause();
    }
  });
});

const video = ref<InstanceType<typeof VideoPlayerModal> | null>(null);
function playVideo() {
  video.value?.play();
}
</script>

<style lang="scss" scoped>
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
