<template>
  <div
    class="box-shadow-option-container relative mx-auto mb-2 flex h-[72px] w-[319px] cursor-pointer items-center rounded-xl border-2 border-[#16114F] py-[12px] pl-[12px] md:mb-0 md:w-[350px]"
    @click.stop.prevent="toggleProject"
  >
    <label class="checkbox-control relative z-10">
      <input
        :value="checkedValue"
        type="checkbox"
        class="selector-checkbox"
        :class="{
          active: checkedValue,
        }"
      />
      <span class="absolute left-3 top-4 z-10 h-[20px] w-[26px] cursor-pointer">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 26 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 9.28571L9.4717 17L24 2"
            stroke="#16114F"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </span>
    </label>
    <span
      class="flex h-4 items-center whitespace-nowrap pl-5 font-Aeonik text-[15px] font-bold uppercase -tracking-[0.02em] text-[#16114F] md:text-[18px] md:-tracking-[0.03em]"
    >
      <slot />
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { Project } from "~/composables/useJoinStore";

interface ProjectSelectorProps {
  type: Project;
}

const props = defineProps<ProjectSelectorProps>();

const joinStore = useJoinStore();
const checkedValue = computed(() =>
  joinStore.data.projects.includes(props.type),
);

// Toggle project selection
const toggleProject = () => {
  if (!checkedValue.value) {
    joinStore.$patch((state) => {
      state.data.projects.push(props.type);
    });
  } else {
    joinStore.$patch((state) => {
      state.data.projects = state.data.projects.filter(
        (project) => project !== props.type,
      );
    });
  }
};
</script>

<style lang="scss" scoped>
.box-shadow-option-container {
  box-shadow: 2px 2px 0px 0px #16114f;
}
.checkbox-control {
  width: 48px;
  height: 48px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  border-radius: 12px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: inherit;
  /* Not removed via appearance */
  margin: 0;
  width: 48px;
  height: 48px;
  border-width: 2px;
  border-style: solid;
  border-radius: 12px;
  border-color: #16114f;
  display: grid;
  place-content: center;
  box-shadow: 2px 2px 0px 0px #16114f;
  cursor: pointer;
}

label:hover {
  background-color: white;
}

.selector-checkbox ~ span svg {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 37px;
  stroke-dashoffset: 37px;
  transition-property: animation, stroke-dasharray, stroke-dashoffset;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.selector-checkbox.active ~ span svg {
  stroke-dashoffset: 0;
  animation: wave-4 0.4s ease;
}

@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
</style>
