<template>
  <div>
    <!-- Globe: will go to scale: 0 while it turns 360 degrees -->
    <img
      v-motion
      :initial="{
        scale: 1,
        rotate: 0,
      }"
      :enter="{
        scale: 0,
        rotate: 360,
        transition: {
          delay: 9000,
        },
      }"
      src="/images/join/searching/globe.png"
      class="absolute -right-2 md:right-0"
    />

    <!-- Big Star-struck Emoji: will pop from scale: 0 to 1 after the globe disappears -->
    <img
      ref="starStruckEmoji"
      src="/images/join/searching/single-star-struck.png"
      class="absolute -right-[70px] top-2 md:h-[520px] md:w-[600px]"
    />
    <div v-if="currentIndex < 3" class="h-full w-full">
      <!-- by order of appearance inside the globe -->
      <!-- left icon  -->
      <img
        v-if="currentIndex !== 0"
        :key="iconList[currentIndex] + '1'"
        v-motion="{
          initial: {
            opacity: 1,
            scale: 0,
          },
          enter: {
            opacity: 0,
            scale: 1,
            transition: {
              scale: {
                ...globeEmojisTransition,
              },
              opacity: {
                delay: 2700,
              },
            },
          },
        }"
        :src="`/images/join/searching/${iconList[currentIndex]}.png`"
        class="absolute left-[48px] top-[124px] h-6 w-6 md:left-[160px] md:top-[224px] md:h-[40px] md:w-[40px]"
      />
      <!-- top icon  -->
      <img
        v-if="currentIndex !== 0"
        :key="iconList[currentIndex] + '2'"
        v-motion="{
          initial: {
            opacity: 1,
            scale: 0,
          },
          enter: {
            opacity: 0,
            scale: 1,
            transition: {
              scale: {
                ...globeEmojisTransition,
                delay: 933,
              },
              opacity: {
                delay: 2700,
              },
            },
          },
        }"
        :src="`/images/join/searching/${iconList[currentIndex]}.png`"
        class="absolute left-[151px] top-[29px] h-6 w-6 md:left-[325px] md:top-[30px] md:h-[40px] md:w-[40px]"
      />

      <!-- bottom icon -->
      <img
        v-if="currentIndex !== 0"
        :key="iconList[currentIndex] + '3'"
        v-motion="{
          initial: {
            opacity: 1,
            scale: 0,
          },
          enter: {
            opacity: 0,
            scale: 1,
            transition: {
              scale: {
                ...globeEmojisTransition,
                delay: 1866,
              },
              opacity: {
                delay: 2700,
              },
            },
          },
        }"
        :src="`/images/join/searching/${iconList[currentIndex]}.png`"
        class="absolute left-[150px] top-[176px] h-6 w-6 md:left-[340px] md:top-[309px] md:h-[40px] md:w-[40px]"
      />
      <!-- moving magnifying-glass -->
      <img
        v-motion="{
          initial: {
            x: magGlassCoordinates.initial.x,
            y: magGlassCoordinates.initial.y,
          },
          enter: {
            x: magGlassCoordinates.enter.x,
            y: magGlassCoordinates.enter.y,
            transition: {
              duration: 2800,
              type: 'keyframes',
              ease: 'backInOut',
              delay: 200,
              repeat: Infinity,
            },
          },
        }"
        src="/images/join/searching/single-mag-glass.png"
        class="h-[70px] w-[70px] md:h-[140px] md:w-[140px]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWindowSize } from "@vueuse/core";

interface GlobeProps {
  currentIndex: number;
}

const globeMobile = {
  initial: {
    x: 48,
    y: 124,
  },
  enter: { x: [38, 139, 140, 38], y: [115, 21, 163, 115] },
};

const globeDesktop = {
  initial: {
    x: 70,
    y: 195,
  },
  enter: { x: [130, 300, 310, 130], y: [195, 5, 280, 195] },
};

const { width } = useWindowSize();

const magGlassCoordinates = computed(() => {
  console.log("w:", width.value);

  if (width.value > 640) {
    return globeDesktop;
  } else {
    return globeMobile;
  }
});

const props = defineProps<GlobeProps>();

// ANIMATIONS
const globeEmojisTransition = {
  type: "spring",
  stiffness: 300,
  damping: 20,
  mass: 0.8,
};

// images for the icons
const iconList = ["", "single-chart", "single-lightning", "single-lightning"];

// coordinates for the star-struck emoji movement
const x = 0;
const y = 5;
const r = 1;

// custom variant for the big star-struck emoji
const starStruckEmoji = ref();

const { variant } = useMotion(starStruckEmoji, {
  initial: {
    x: 0,
    y: 0,
    scale: 0,
    rotate: 0,
  },
  enter: {
    scale: 1,
    rotate: -360,
    transition: {
      delay: 9000,
      type: "spring",
      stiffness: 100,
      damping: 20,
      mass: 0.8,
      onComplete: () => {
        variant.value = "custom";
      },
    },
  },
  custom: {
    x: [x, x, x, x, x],
    y: [0, y, 0, y, 0],
    rotate: [0, -r, 0, r, 0],
    transition: {
      type: "keyframes",
      duration: 430,
      repeatType: "loop",
      repeat: Infinity,
    },
  },
});
</script>

<style lang="scss" scoped>
//
</style>
