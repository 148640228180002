<template>
  <div
    class="relative flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-3xl pt-[80px] md:justify-start md:overflow-visible md:rounded-none md:px-4 md:pt-[230px]"
  >
    <!-- HEADER -->
    <JoinTitle
      class="blinking-text order-1 mb-8 ml-6 h-[56px] w-[297px] self-start text-start !text-[36px] !leading-[30px] -tracking-[0.03em] md:order-none md:mb-[60px] md:ml-[58px] md:h-[22px] md:w-[478px] md:text-left md:!text-[34px] md:-tracking-[0.03em]"
    >
      {{ messageList[currentIndex] }}</JoinTitle
    >
    <!-- BODY -->
    <!-- MARQUEE -->
    <JoinStepSearchingElementMarquee :current-index="currentIndex" />
    <!-- PROGRESS BAR -->
    <div
      class="order-2 mb-[41px] flex w-[306px] gap-[2px] rounded-[10px] border border-[#16114F] bg-[#FFFFFF] py-[4px] pl-[3px] shadow-[2px_2px_0px_0px_#16114F] md:order-none md:mb-0 md:ml-[60px] md:mt-[60px] md:h-[38px] md:w-[396px] md:items-center md:self-start md:rounded-[6px]"
    >
      <div
        v-for="n in loadingBars"
        :key="n"
        :class="[
          n == 1 ? 'rounded-l-[10px] md:rounded-l-[6px]' : '',
          n == 15 ? 'rounded-r-[10px] md:rounded-r-[6px]' : '',
          n > 15 && 'hidden',
        ]"
        class="h-[28px] w-[18px] flex-shrink-0 bg-[#00E96B] md:h-[30px] md:w-[24px]"
      />
    </div>

    <!-- GLOBE -->
    <div
      class="relative order-4 h-[329px] w-[336px] md:absolute md:right-0 md:top-[80px] md:order-none md:block md:h-[556.01px] md:w-[551.07px] md:overflow-hidden"
    >
      <JoinStepSearchingElementGlobe :current-index="currentIndex" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { goToNext } = useJoinStore();
// add vue route
const route = useRoute();

// ANIMATION TIME: 11 SECONDS + delay using green bars in the progress element

// time related constants for setInterval
const FADE_OUT_INTERVAL = 2000;
const BLINK_AND_IMG_CHANGE_INTERVAL = 2800;
const LOADING_BAR_INTERVAL = 733;
// rendering related (used to trigger the transition to the next page)
const MAX_LOADING_BARS = 20;

const messageList = [
  "Scanning the planet for solutions...",
  "Analyzing the impact...",
  "Identifying top solutions...",
  "Crafting your custom impact plan...",
];

const currentIndex = ref(0);
const loadingBars = ref(1);
const isFadingOut = ref(false);

let timerTextFadeOut: NodeJS.Timeout;
let timerTextBlink: NodeJS.Timeout;
let timerLoadingBars: NodeJS.Timeout;

const clearIntervalIfOutOfArrayBounds = (
  intervalId: NodeJS.Timeout,
  array: string[],
) => {
  if (currentIndex.value === array.length - 1) {
    clearInterval(intervalId);
    return;
  }
};

// Animation configuration for the searching effect

onMounted(() => {
  if (route.query.step === "searching") {
    timerTextFadeOut = setInterval(() => {
      // starts to fade out the text as the change is about to happen
      if (currentIndex.value < 3) {
        isFadingOut.value = true;
      }
      clearIntervalIfOutOfArrayBounds(timerTextFadeOut, messageList);
    }, FADE_OUT_INTERVAL);

    timerTextBlink = setInterval(() => {
      // change the img & disables fade out so the new image can appear from a clean slate
      isFadingOut.value = false;
      currentIndex.value++;
      clearIntervalIfOutOfArrayBounds(timerTextBlink, messageList);
    }, BLINK_AND_IMG_CHANGE_INTERVAL);

    timerLoadingBars = setInterval(() => {
      // load green progress bars and when it's full, go to the next page
      loadingBars.value++;
      if (loadingBars.value > MAX_LOADING_BARS) {
        clearInterval(timerLoadingBars);
        goToNext();
      }
    }, LOADING_BAR_INTERVAL);
  }
});

onUnmounted(() => {
  clearInterval(timerTextFadeOut);
  clearInterval(timerTextBlink);
  clearInterval(timerLoadingBars);
});
</script>

<style lang="scss" scoped>
.blinking-text {
  animation: blink 1.1s ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
</style>
