import JoinStepEmail from "./Email.vue";
import JoinStepImpactStyle from "./ImpactStyle.vue";
import JoinStepPayment from "./Payment.vue";
import JoinStepSearching from "./Searching/index.vue";
import JoinStepSpecific from "./Specific.vue";
import JoinStepSubscriptionSelector from "./SubscriptionSelector/index.vue";

export const JoinSteps = [
  {
    name: "impact-style",
    number: 1,
    component: JoinStepImpactStyle,
  },
  {
    name: "email",
    number: 2,
    component: JoinStepEmail,
  },
  {
    name: "specific",
    number: 3,
    component: JoinStepSpecific,
  },
  {
    name: "searching",
    number: 4,
    component: JoinStepSearching,
  },
  {
    name: "subscription",
    number: 5,
    component: JoinStepSubscriptionSelector,
  },
  {
    name: "payment",
    number: 6,
    component: JoinStepPayment,
  },
];
