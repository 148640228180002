<template>
  <div
    class="join-box-background join-box-shadow w-full flex-grow rounded-[30px] border-[3px] border-navy"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.join-box-shadow {
  filter: drop-shadow(3px 3px 0px #16114f);
}

.join-box-background {
  background: linear-gradient(155.28deg, #ffe82b -5.05%, #fffcff 85.02%);
}
</style>
