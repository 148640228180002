import { defineStore } from "pinia";

type JoinStep =
  | "impact-style"
  | "email"
  | "specific"
  | "searching"
  | "subscription"
  | "payment";
const steps = [
  "impact-style",
  "email",
  "specific",
  "searching",
  "subscription",
  "payment",
] as const;

export type Project = "earth" | "oceans" | "wildlife" | "humanity";
interface JoinData {
  projects: Project[];
  email: string;
  subprojects: {
    earth: string[];
    wildlife: string[];
    oceans: string[];
    humanity: string[];
  };
}

export const useJoinStore = defineStore(
  "join-store",
  () => {
    const route = useRoute();
    const router = useRouter();

    // User provided data
    const data = reactive<JoinData>({
      projects: [],
      email: "",
      subprojects: {
        earth: [],
        wildlife: [],
        oceans: [],
        humanity: [],
      },
    });

    // methods
    const currentStep = computed(() => {
      const step = route.query.step;

      if (step && steps.includes(step as JoinStep)) {
        return step as JoinStep;
      }

      return "impact-style";
    });

    const currentStepNumber = computed(() => {
      return steps.indexOf(currentStep.value) + 1;
    });

    // Navigation
    const goToNext = () => {
      console.log("JOIN STORE - goToNext");
      const currentIndex = steps.indexOf(currentStep.value);
      const nextStep = steps[currentIndex + 1];
      router.push({ query: { step: nextStep } });
    };

    const goBack = () => {
      if (currentStep.value == "subscription") {
        router.push({ query: { step: "specific" } });
        return;
      }

      const currentIndex = steps.indexOf(currentStep.value);
      const previousStep = steps[currentIndex - 1];
      router.push({ query: { step: previousStep } });
    };

    return {
      currentStep,
      currentStepNumber,
      data,
      goToNext,
      goBack,
    };
  },
  {
    persist: true,
  },
);
