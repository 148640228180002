<template>
  <div>
    <DialogRoot>
      <DialogTrigger class="z-50 focus:outline-none">
        <slot />
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay class="fixed inset-0 z-30" />
        <DialogContent
          class="fixed left-[50%] top-[50%] z-[100] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] border border-[#16114F] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
        >
          <DialogTitle class="m-0 text-[17px] font-semibold">
            Would you like to remove this project?
          </DialogTitle>

          <div class="mt-[25px] flex flex-col justify-around gap-5 md:flex-row">
            <DialogClose as-child>
              <button
                class="inline-flex h-[35px] items-center justify-center rounded-[4px] bg-green-200 px-[15px] font-semibold leading-none text-green-800 hover:bg-green-400 focus:shadow-[0_0_0_2px] focus:shadow-green-200 focus:outline-none md:flex-1"
                @click="closeDialog"
              >
                Keep
              </button>
            </DialogClose>

            <DialogClose as-child>
              <button
                class="inline-flex h-[35px] items-center justify-center rounded-[4px] bg-red-200 px-[15px] font-semibold leading-none text-red-800 hover:bg-red-400 focus:shadow-[0_0_0_2px] focus:shadow-red-200 focus:outline-none md:flex-1"
                @click="removeSubscription"
              >
                Remove
              </button>
            </DialogClose>
          </div>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  </div>
</template>

<script lang="ts" setup>
const joinStore = useJoinStore();

interface SubscriptionCancellationProps {
  theme?: string;
}

const props = defineProps<SubscriptionCancellationProps>();
const open = defineModel("open");

const closeDialog = () => {
  open.value = false;
};

const removeSubscription = () => {
  closeDialog();
  // removes subscription from the store
  joinStore.$patch((state) => {
    if (props.theme) {
      state.data.subprojects[props.theme as Project] = [];
    }
  });
};
</script>

<style lang="scss" scoped>
//
</style>
