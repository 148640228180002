export const motions = {
  impactStyleSubtitle: {
    initial: {
      opacity: 0,
    },
    visibleOnce: {
      opacity: 1,
      transition: {
        duration: 1000,
        delay: 500,
      },
    },
  },
  impactStyleProjects: {
    initial: {
      scale: 0,
    },
    enter: {
      scale: 1,
      transition: {
        duration: 600,
        type: [0.34, 1.56, 0.64, 1],
      },
    },
  },
};
