<template>
  <div>
    <!-- DESKTOP TITLE -->
    <JoinTitle
      class="hidden whitespace-pre-wrap px-[37px] pt-20 md:block md:px-[72px] md:pt-[91px] md:-tracking-[0.04em]"
    >
      <template v-for="(letter, index) in title" :key="index">
        <span
          v-if="index !== 25"
          v-motion
          class="inline-block origin-bottom"
          :initial="{
            scale: 0,
          }"
          :enter="{
            scale: 1,
            transition: {
              type: 'spring',
              stiffness: 60,
              damping: 7,
              mass: 0.8,
              delay: index * 20,
            },
          }"
          >{{ letter }}</span
        >
        <span v-else><br /></span>
      </template>
    </JoinTitle>
    <!-- MOBILE TITLE -->
    <JoinTitle
      class="whitespace-pre-wrap px-[37px] pt-20 -tracking-[0.02em] md:hidden md:px-[72px] md:pt-[91px]"
    >
      <template v-for="(letter, index) in title" :key="index">
        <span
          v-if="![16, 31].includes(index)"
          v-motion
          class="inline-block origin-bottom"
          :initial="{
            scale: 0,
          }"
          :enter="{
            scale: 1,
            transition: {
              type: 'spring',
              stiffness: 60,
              damping: 7,
              mass: 0.8,
              delay: index * 20,
            },
          }"
          >{{ letter }}</span
        >
        <span v-else><br /></span>
      </template>
    </JoinTitle>

    <JoinSubtitle class="mt-4 -tracking-[0.02em] md:mt-6 md:-tracking-[0.03em]"
      >Made just for you!</JoinSubtitle
    >

    <!-- BODY -->
    <div
      class="mx-4 mt-[46px] rounded-xl border border-[#16114F] bg-[#EBF5B0] p-3 shadow-[2px_2px_0px_0px_#16114F] md:mx-[119px] md:mt-[48px] md:p-8"
    >
      <JoinSubtitle
        class="hidden text-left font-Kontesa !text-[32px] !leading-none md:inline"
      >
        Suggested Subscription
      </JoinSubtitle>
      <div class="md:mb-8" />
      <JoinStepSubscriptionSelectorItem
        v-for="(project, index) in filteredSubprojectKeys"
        :key="index"
        v-model="currentValues[project]"
        :project-title="titleSelector(project)"
        :campaign-icon="iconSelector(project)"
        :impact-ratio="impactRatioSelector(project)"
        :min="0"
        :max="30"
        :bg-color="colorSelector(project)"
        :video-player-id="videoPlayerIdSelector(project)"
        :image-src="imageSourceSelector(project)"
        :campaign-id="campaignSelector(project)"
        :theme="project"
        class="mb-3 md:mb-5"
      />

      <div
        class="my-6 h-1 w-full border-t-[1px] border-dotted border-[#16114F]"
      />
      <!-- DONATIONS -->
      <div
        class="flex h-12 w-full items-center justify-between rounded-xl border-[1.4px] border-[#16114F] bg-[#FEE6EE] px-3 py-[17px] uppercase md:h-[82px] md:rounded-md md:px-6 md:py-8"
      >
        <span
          class="text-center font-Aeonik text-xs font-bold leading-[14.4px] -tracking-[0.01] text-[#16114F] md:text-[22px] md:leading-5 md:-tracking-[0.03em]"
          >Total Monthly Donation</span
        >
        <span
          class="text-center font-Kontesa text-4xl font-extrabold leading-8 -tracking-[0.03em] text-[#16114F] md:text-[54px] md:leading-[43px] md:-tracking-[0.02em]"
        >
          ${{ totalAmountDonated }}
        </span>
      </div>
    </div>

    <div
      class="mb-[57px] mt-8 flex w-full justify-center space-x-3 px-4 md:mt-10"
    >
      <Button
        variant="icon"
        class="min-h-[50px] min-w-[50px] flex-shrink-0"
        @click="goBack"
      >
        <img src="/images/join/email/arrow.png" class="w-[14px]" />
      </Button>
      <JoinButton @click="$emit('next')">
        <span class="inline md:hidden">Continue</span>
        <span class="hidden md:inline">Enter payment info</span>
      </JoinButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Campaign } from "~/lib/campaigns";

defineEmits<{
  (e: "next"): void;
}>();

const { goBack } = useJoinStore();
const joinStore = useJoinStore();

const title = "Holy crap you’re about to do so much good!".split("");

interface currentValuesType {
  [key: string]: number[];
}

// Only display projects containing at least one value
const filteredSubprojectKeys = computed(() => {
  return Object.entries(joinStore.data.subprojects)
    .filter(([_, projects]) => projects.length > 0)
    .map(([key, _]) => key as keyof typeof joinStore.data.subprojects);
});

// Where the amount donated will be stored
const currentValues = ref<currentValuesType>({
  earth: [5],
  wildlife: [5],
  oceans: [5],
  humanity: [5],
});

const totalAmountDonated = computed(() => {
  let sum = 0;
  for (const key of filteredSubprojectKeys.value) {
    sum += currentValues.value[key][0];
  }
  return sum;
});

const titleSelector = (project: string) => {
  if (project === "earth") {
    return "Plant Trees";
  } else if (project === "wildlife") {
    return "Help Animals";
  } else if (project === "oceans") {
    return "Remove Plastic";
  } else if (project === "humanity") {
    return "Days of Education";
  }
};

const iconSelector = (project: string) => {
  if (project === "earth") {
    return "/images/icons/tree.png";
  } else if (project === "wildlife") {
    return "/images/icons/turtle.png";
  } else if (project === "oceans") {
    return "/images/icons/soda.png";
  } else if (project === "humanity") {
    return "/images/icons/book.png";
  }
};

const colorSelector = (project: string) => {
  if (project === "earth") {
    return "bg-[#73F3AE]";
  } else if (project === "wildlife") {
    return "bg-[#FA8257]";
  } else if (project === "oceans") {
    return "bg-[#61E5FF]";
  } else if (project === "humanity") {
    return "bg-[#FFE617]";
  }
};

const impactRatioSelector = (project: string) => {
  if (project === "earth") {
    return 2.8;
  } else if (project === "wildlife") {
    return 0.3;
  } else if (project === "oceans") {
    return 2.2;
  } else if (project === "humanity") {
    return 2;
  }
};

const campaignSelector = (project: keyof typeof joinStore.data.subprojects) => {
  switch (project) {
    case "earth":
      return Campaign.TFTF;
    case "wildlife":
      return Campaign.OVI;
    case "oceans":
      return Campaign.PF;
    case "humanity":
      return Campaign.SARTHAK;
  }
};

const breakpoints = useBreakpoints({
  sm: useTheme().screens.sm,
});
const isMobile = computed(() => breakpoints.smaller("sm").value);

const videoPlayerIdSelector = (project: string) => {
  if (project === "earth") {
    return isMobile.value ? "947566271?h=02d560ff0b" : "947566193?h=a387efbd45";
  } else if (project === "wildlife") {
    return isMobile.value ? "947563444?h=dc7ce21ed5" : "947563401?h=5bbc710dc8";
  } else if (project === "oceans") {
    return isMobile.value ? "947565579?h=d2ac678268" : "947564310?h=13f7ded16a";
  } else if (project === "humanity") {
    return isMobile.value ? "947560177?h=b16b331a51" : "947560154?h=76f9551275";
  }
};

const imageSourceSelector = (project: string) => {
  if (project === "earth") {
    return "/images/join/subscription/campaign-trees.png";
  } else if (project === "wildlife") {
    return "";
  } else if (project === "oceans") {
    return "";
  } else if (project === "humanity") {
    return "https://i.vimeocdn.com/video/1453688804-de886a99f9b1dde00e16110a6bd7c49d5ebdeca50abcccdb81b68208a610998c-d_1280";
  }
};
</script>
