<template>
  <div class="px-4 pt-[80px] lg:pt-[160px]">
    <JoinTitle
      class="relative mb-4 h-[56px] whitespace-pre md:mb-[17px] md:h-[66px]"
    >
      <template v-for="(letter, index) in title" :key="index">
        <span
          v-if="letter !== 'a'"
          v-motion
          class="inline-block origin-bottom"
          :initial="{
            scale: 0,
          }"
          :enter="{
            scale: 1,
            transition: {
              type: 'spring',
              stiffness: 60,
              damping: 7,
              mass: 0.8,
              delay: 200 + index * 20,
            },
          }"
          >{{ letter }}</span
        >
        <span v-else class="-ml-1 md:-ml-[9px]">a</span>
      </template>
    </JoinTitle>

    <JoinSubtitle
      v-motion="motions.impactStyleSubtitle"
      class="mx-auto mb-[46px] max-w-[297px] md:mb-[74px] md:max-w-[648px]"
      >What’s your flavor of making the world a better place?</JoinSubtitle
    >
    <div
      class="relative mx-auto grid-cols-2 gap-3 md:grid md:max-w-[720px] md:gap-5"
    >
      <JoinToastPig :open="hasSelectionError" class="top-0 z-0">
        Please select at least one theme!
      </JoinToastPig>

      <JoinProjectSelector
        v-motion
        :initial="{
          scale: 0,
        }"
        :enter="{
          scale: 1,
          transition: {
            delay: baseDelay,
            ...selectorSpring,
          },
        }"
        type="earth"
        class="z-10 w-full bg-[#D7EB61]"
        :class="[{ 'gentle-tilt-move-shake': hasSelectionError }]"
      >
        Helping the Earth
      </JoinProjectSelector>

      <JoinProjectSelector
        v-motion
        :initial="{
          scale: 0,
        }"
        :enter="{
          scale: 1,
          transition: {
            delay: baseDelay + delayStep,
            ...selectorSpring,
          },
        }"
        type="oceans"
        class="w-full bg-[#61E5FF]"
        :class="[{ 'gentle-tilt-move-shake': hasSelectionError }]"
      >
        Helping the Oceans
      </JoinProjectSelector>

      <JoinProjectSelector
        v-motion
        :initial="{
          scale: 0,
        }"
        :enter="{
          scale: 1,
          transition: {
            delay: baseDelay + delayStep * 2,
            ...selectorSpring,
          },
        }"
        type="wildlife"
        class="w-full bg-[#FA8257]"
        :class="[{ 'gentle-tilt-move-shake': hasSelectionError }]"
      >
        Helping Wildlife & Animals
      </JoinProjectSelector>

      <JoinProjectSelector
        v-motion
        :initial="{
          scale: 0,
        }"
        :enter="{
          scale: 1,
          transition: {
            delay: baseDelay + delayStep * 3,
            ...selectorSpring,
          },
        }"
        type="humanity"
        class="w-full bg-[#FFE617]"
        :class="[{ 'gentle-tilt-move-shake': hasSelectionError }]"
      >
        Helping humanity
      </JoinProjectSelector>
    </div>
    <div
      v-motion-pop
      :delay="2000"
      class="mx-auto flex max-w-[319px] justify-center"
    >
      <button
        variant="primary-join"
        class="mb-[45px] mt-8 w-full min-w-min rounded-full border-2 border-[#16114F] !bg-[#5090f3] pb-[16px] pt-[17px] text-[15px] font-bold uppercase leading-[13px] -tracking-[0.02em] text-white shadow-[0px_2px_0px_0px_#16114F] hover:!bg-yellow hover:!text-[#16114F] active:!bg-white md:mb-[125px] md:mt-10 md:py-[14px] md:text-lg md:normal-case md:leading-[18px]"
        @click="nextPage"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { motions } from "../motions";
const title = "your impact-style quiz".split("");

const hasSelectionError = ref(false);

const { data } = storeToRefs(useJoinStore());

watch(
  () => data.value.projects,
  () => {
    if (data.value.projects.length > 0) {
      hasSelectionError.value = false;
    }
  },
  { deep: true },
);

const emit = defineEmits(["next"]);

const nextPage = () => {
  // Do not allow user to continue until they select at least one project
  if (data.value.projects.length === 0) {
    hasSelectionError.value = true;
    return;
  }
  emit("next");
};

const baseDelay = 800;
const delayStep = 180;

const selectorSpring = {
  type: "spring",
  stiffness: 120,
  damping: 9,
  mass: 0.5,
};
</script>

<style lang="scss" scoped>
.gentle-tilt-move-shake {
  animation: tilt-shaking 0.25s 4;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
