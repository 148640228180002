<template>
  <div
    class="relative flex flex-col items-center justify-center space-y-[15px] pt-[19px] md:flex-row md:space-x-3 md:space-y-0 md:pt-[24px]"
  >
    <SliderRoot
      v-model="rangeValue"
      class="relative flex h-[36px] w-full touch-none select-none items-center md:h-[40px] md:w-3/5"
      :max="max"
      :min="min"
      :step="1"
      @update:model-value="handleRangeInput"
    >
      <SliderTrack
        class="relative h-full w-full rounded-[10px] border border-[#16114F] bg-white shadow-[2px_2px_0px_0px_rgb(22,17,79)] md:rounded-md"
      >
        <SliderRange
          class="absolute flex items-center justify-between gap-[2px] overflow-hidden p-[2.4px]"
        >
          <div
            v-for="n in 20"
            :key="n"
            :class="[
              n == 1 ? 'rounded-l-[10px] md:rounded-l-md' : '',
              n == 20 ? 'rounded-r-[10px] md:rounded-r-md' : '',
            ]"
            class="h-[30px] min-w-[19px] grow bg-[#00E96B] md:h-[34px] md:min-w-[25px]"
          />
        </SliderRange>
      </SliderTrack>

      <SliderThumb
        :class="`h-[42px] min-w-[68px] rounded-[10px] border-[1px] border-[#16114F] p-[5px] outline-none md:h-[52px] md:min-w-[92px] md:rounded-[12px] ${bgColor} grid cursor-pointer place-content-center shadow-[1px_1px_0px_0px_rgb(22,17,79)]`"
        aria-label="Amount"
      >
        <span
          class="whitespace-nowrap font-Kontesa text-[21px] font-bold leading-[19px] -tracking-[0.02em] text-[#16114F] md:text-[32px] md:leading-[32px]"
        >
          ${{ rangeValue[0] }}
        </span>
      </SliderThumb>
    </SliderRoot>

    <!-- Manual input -->
    <div class="relative h-[36px] w-full md:h-[40px] md:w-2/5">
      <input
        ref="manualInput"
        :max="max"
        :min="min"
        :value="manualValue"
        class="h-full w-full rounded-[10px] border border-[#16114F] pl-[22px] font-Kontesa text-[21px] font-bold leading-[21px] text-[#16114F] shadow-[2px_2px_0px_0px_rgb(22,17,79)] outline-none md:rounded-md md:text-[32px] md:leading-[32px]"
        @input.prevent="handleManualInput"
        @blur="handleManualInputBlur"
      />

      <button
        v-if="inputModelMode === 'range'"
        class="border-with-shadow absolute left-0 h-full w-full rounded-[10px] bg-white text-[14px] font-bold uppercase -tracking-[0.03em] text-[#16114F] md:rounded-md"
        @click="startManualMode"
      >
        <span
          class="absolute left-0 top-1/2 ml-3 -translate-y-1/2 opacity-50 md:ml-2"
          >Or write the amount here...</span
        >
      </button>

      <span
        v-if="inputModelMode === 'manual'"
        class="pointer-events-none absolute left-0 top-1/2 ml-3 -translate-y-1/2 font-Kontesa text-[21px] font-bold leading-[21px] -tracking-[0.02em] text-[#16114F] md:ml-2 md:text-[32px] md:leading-[32px]"
        >$</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
interface ItemInputsProps {
  min: number;
  max: number;
  bgColor?: string;
  modelValue: number;
}

const props = defineProps<ItemInputsProps>();
const emit = defineEmits<{
  (e: "update:modelValue", value: number): void;
}>();

// Models
const inputModelMode = ref<"manual" | "range">("range");

const manualValue = ref("");
const rangeValue = ref([props.modelValue]);

const manualInput = ref();
const { focused } = useFocus(manualInput);

// Methods
const startManualMode = () => {
  inputModelMode.value = "manual";
  manualValue.value = "";
  focused.value = true;
};

const handleRangeInput = () => {
  inputModelMode.value = "range";

  emit("update:modelValue", rangeValue.value[0]);
};

const handleManualInput = (ev: Event) => {
  const rawValue = (ev.target as HTMLInputElement)?.value
    .replace(/[^\d.]/g, "")
    .replace(/\.(?=.*\.)/g, "")
    .replace(/^0+/, "");

  const data = (ev as InputEvent).data;

  manualValue.value = rawValue;

  const parts = manualValue.value.split(/[.,]/);

  let value = 0;

  if (parts.length > 1) {
    if (parts[1]) {
      value = parseFloat(parts[0] + "." + parts[1]);
    } else {
      value = parseFloat(parts[0]);
    }
  } else {
    if (parts[0]) {
      value = parseFloat(parts[0]);
    } else {
      value = !data ? 0 : rangeValue.value[0];
    }
  }

  rangeValue.value = [value];

  emit("update:modelValue", value);
};

const handleManualInputBlur = () => {
  if (manualValue.value === "") {
    inputModelMode.value = "range";
  }
};

onMounted(() => {
  const { counter, pause } = useInterval(50, { controls: true });
  // Save initial value as a stopping signal
  const initialModelValue = props.modelValue;
  // Set the starting value
  rangeValue.value[0] = 0;
  // Have the counter go until it reaches the initial value and then pause the counter
  watch(counter, () => {
    if (rangeValue.value[0] < initialModelValue) {
      rangeValue.value[0]++;
    } else {
      pause();
    }
  });
});
</script>

<style lang="scss" scoped>
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
