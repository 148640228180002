<template>
  <div
    v-motion
    :initial="{
      scale: 0.1,
      x: 0,
      opacity: 1,
    }"
    :enter="{
      scale: 1,
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 40,
        mass: 0.8,
      },
    }"
    class="origin-top px-4 pt-[80px] md:pt-[91px]"
  >
    <!-- HEADER -->
    <!-- DESKTOP TITLE -->
    <JoinTitle
      class="mx-auto mb-[37px] hidden h-[56px] w-[193px] whitespace-pre-wrap md:mb-[24px] md:block md:h-[66px] md:w-[922px]"
    >
      <template v-for="(letter, index) in title" :key="index">
        <span
          v-motion
          class="inline-block origin-bottom"
          :initial="{
            scale: 0,
          }"
          :enter="{
            scale: 1,
            transition: {
              type: 'spring',
              stiffness: 60,
              damping: 7,
              mass: 0.8,
              delay: index * 20,
            },
          }"
          >{{ letter }}</span
        >
      </template>
    </JoinTitle>
    <!-- MOBILE TITLE -->
    <JoinTitle
      class="mx-auto mb-[37px] h-[56px] w-[193px] whitespace-pre-wrap md:mb-[24px] md:hidden md:h-[66px] md:w-[922px]"
    >
      <template v-for="(letter, index) in title" :key="index">
        <span
          v-if="index !== 9"
          v-motion
          class="inline-block origin-bottom"
          :initial="{
            scale: 0,
          }"
          :enter="{
            scale: 1,
            transition: {
              type: 'spring',
              stiffness: 60,
              damping: 7,
              mass: 0.8,
              delay: index * 20,
            },
          }"
          >{{ letter }}</span
        >
        <span v-else><br /></span>
      </template>
    </JoinTitle>

    <JoinSubtitle
      v-motion-fade
      class="mx-auto mb-[38px] max-w-[297px] md:mb-[44px] md:max-w-[648px]"
      >what really lights you up?</JoinSubtitle
    >
    <!-- BODY -->
    <JoinSubtitle
      v-motion-fade
      class="mx-auto mb-[18px] max-w-[297px] font-medium normal-case leading-[18px] -tracking-[0.03em] md:mb-[34px] md:max-w-[648px]"
      >(select multiple)</JoinSubtitle
    >

    <div
      class="relative mx-auto mb-8 flex w-fit origin-top flex-col items-center gap-3 md:mb-10"
    >
      <JoinToastPig :open="hasSelectionError" class="absolute">
        Please select at least one theme!
      </JoinToastPig>
      <JoinSubprojectSelector
        v-if="data.projects.includes('earth')"
        header-and-checkmark-color="bg-[#D7EB61]"
        body-color="bg-[#EBF5B0]"
        header-title="Helping the Earth"
        :subprojects="earth"
        project="earth"
        :index="0"
      />

      <JoinSubprojectSelector
        v-if="data.projects.includes('wildlife')"
        header-and-checkmark-color="bg-[#FA8257]"
        body-color="bg-[#FFBCB5]"
        header-title="Helping Wildlife & Animals"
        :subprojects="wildlife"
        project="wildlife"
        :index="1"
      />

      <JoinSubprojectSelector
        v-if="data.projects.includes('humanity')"
        header-and-checkmark-color="bg-[#FFE617]"
        body-color="bg-[#FFE617]"
        header-title="Helping humanity"
        :subprojects="humanity"
        project="humanity"
        :index="2"
      />

      <JoinSubprojectSelector
        v-if="data.projects.includes('oceans')"
        header-and-checkmark-color="bg-[#61E5FF]"
        body-color="bg-[#A0F0FF]"
        header-title="Helping the Oceans"
        :subprojects="oceans"
        project="oceans"
        :index="3"
      />
    </div>
    <!-- BUTTONS -->
    <div class="mb-[57px] flex justify-center space-x-3">
      <Button
        variant="icon"
        class="min-h-[50px] min-w-[50px] flex-shrink-0"
        @click="goBack"
      >
        <img src="/images/join/email/arrow.png" class="w-[14px]" />
      </Button>
      <JoinButton @click="nextPage"> Continue </JoinButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { goBack } = useJoinStore();
const { data } = storeToRefs(useJoinStore());

const emit = defineEmits(["next"]);

const hasSelectionError = ref(false);

const title = "Let’s get more specific!".split("");

const earth: string[] = [
  "Protecting ecosystems",
  "Planting trees and crushing CO2",
  "Restoring Soil Health",
  "Clean up & prevent pollution",
  "Regenerative agriculture",
];

const wildlife: string[] = [
  "Animal welfare",
  "Protecting endangered orangutans",
  "Protecting endangered whales",
  "Protecting endangered pandas",
  "Protecting endangered sloths",
];

const humanity: string[] = [
  "Eliminate conditional hunger",
  "End human trafficking",
  "Protect and Preserve Indigenous Lands",
  "Provide Affordable, Low-emission Energy to All",
  "Provide universal access to drinking water",
];

const oceans: string[] = [
  "The Great Barrier Reef",
  "The Maldives Reefs",
  "The Palancar Reef",
  "The Wakatobi Reefs",
  "The Tubbataha Reefs",
];

const isSubprojectsEmpty = () => {
  for (const key in data.value.subprojects) {
    if (data.value.subprojects[key as Project].length > 0) {
      return false;
    }
  }
  return true;
};

watch(
  () => data.value.subprojects,
  () => {
    if (!isSubprojectsEmpty()) {
      hasSelectionError.value = false;
    }
  },
  { deep: true },
);

const nextPage = () => {
  if (isSubprojectsEmpty()) {
    hasSelectionError.value = true;
    return;
  }
  emit("next");
};

onMounted(() => {
  for (const key in data.value.subprojects) {
    if (!data.value.projects.includes(key as Project)) {
      data.value.subprojects[key as Project] = [];
    }
  }
});
</script>

<style lang="scss" scoped>
//
</style>
