<template>
  <div class="flex" @click="toggleCheckbox">
    <div
      class="checkbox-control relative z-10 flex-shrink-0"
      :class="headerAndCheckmarkColor"
    >
      <div class="rounded-xl">
        <input
          type="checkbox"
          class="subproject-checkbox"
          :class="{
            checked: isChecked,
          }"
        />
        <span
          class="absolute left-2 top-3 z-10 h-[13px] w-[23px] cursor-pointer"
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 26 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 9.28571L9.4717 17L24 2"
              stroke="#16114F"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </span>
      </div>
    </div>
    <label
      class="flex cursor-pointer select-none items-center pl-4 font-Aeonik text-[15px] font-medium normal-case leading-[18px] text-[#16114F] md:text-[18px] md:leading-[16.2px] md:-tracking-[0.03em]"
    >
      {{ props.subproject }}
    </label>
  </div>
</template>

<script lang="ts" setup>
const joinStore = useJoinStore();

interface CheckboxProps {
  subproject: string;
  project: Project;
  headerAndCheckmarkColor: string;
}

const isChecked = computed(() => {
  const subprojects = joinStore.data.subprojects;
  const subprojectData = subprojects[props.project];
  return subprojectData.includes(props.subproject);
});

const toggleCheckbox = () => {
  joinStore.$patch((state) => {
    if (isChecked.value) {
      state.data.subprojects[props.project] = state.data.subprojects[
        props.project
      ].filter((item) => item !== props.subproject);
    } else {
      state.data.subprojects[props.project].push(props.subproject);
    }
  });
};

const props = defineProps<CheckboxProps>();
</script>

<style scoped lang="scss">
.checkbox-control {
  width: 37px;
  height: 37px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  border-radius: 8px;
}

.subproject-checkbox {
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: inherit;
  /* Not removed via appearance */
  margin: 0;
  width: 37px;
  height: 37px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: #16114f;
  display: grid;
  place-content: center;
  box-shadow: 1px 1px 0px 0px #16114f;
  cursor: pointer;
}

.checkbox-control div {
  @apply md:hover:bg-white;
}

.subproject-checkbox ~ span svg {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 37px;
  stroke-dashoffset: 37px;
  transition-property: animation, stroke-dasharray, stroke-dashoffset;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.subproject-checkbox.checked ~ span svg {
  stroke-dashoffset: 0;
  animation: wave-4 0.4s ease;
}

@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
</style>
