<template>
  <div
    class="text-center font-Aeonik text-[15px] font-bold uppercase leading-[13.5px] -tracking-[0.02em] text-[#16114F] md:text-[22px] md:leading-[20px] md:-tracking-[0.03em]"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped></style>
