<template>
  <div
    class="text-center font-Kontesa text-4xl font-bold uppercase leading-8 -tracking-[0.03em] text-[#16114F] md:text-[100px] md:leading-[80px] md:-tracking-[0.02em]"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
//
</style>
