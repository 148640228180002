<template>
  <div
    class="relative flex min-h-screen flex-col bg-gradient-to-b from-main-bg to-ocean pb-96"
  >
    <NuxtPage />
    <div
      class="mx-auto w-full max-w-[1288px] overflow-x-hidden px-2 pb-[60px] md:pb-0"
    >
      <JoinSlider :current-step="currentStepNumber" :total-steps="6" />

      <div
        class="relative flex w-full flex-col overflow-hidden pb-1 md:min-h-[716px]"
      >
        <div
          v-for="(step, index) in JoinSteps"
          :id="`join-${index}`"
          :key="step.name"
          class="join-level"
          :test="index"
          :class="{
            absolute: index + 1 !== currentStepNumber,
            'pointer-events-none': index + 1 !== currentStepNumber,
          }"
        >
          <!-- mx-2 prevents overflow-hidden above from cutting the box-shadow -->
          <JoinContentBox class="mx-2">
            <Component
              :is="step.component"
              v-if="hasBeenMounted && shouldBeRendered(index + 1)"
              @next="handleNext"
            />
          </JoinContentBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { JoinSteps } from "~/components/Join/Step";

const { currentStepNumber } = storeToRefs(useJoinStore());
const { goToNext, goBack } = useJoinStore();

watch(currentStepNumber, (value, oldValue) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });

  elements.value.forEach((element) => {
    element.animate();
  });
});

// Store mounted status
const hasBeenMounted = ref(false);

onMounted(() => {
  instantiateSteps();
  mountElements();
});

// Store useMotion instances for each element
const elements = ref<
  {
    name: string;
    motion: any;
    animate: () => void;
    mount: () => void;
  }[]
>([]);

// Check if we're on mobile
const isMobile = computed(() => {
  return window.innerWidth < 768;
});

const shouldBeRendered = (index: number) => {
  return (
    index === currentStepNumber.value || index === currentStepNumber.value - 1
  );
};

// Instantiate steps
const instantiateSteps = () => {
  elements.value = JoinSteps.map((step, index) => {
    function getStepCurrentParameters() {
      const currentPageIndex = currentStepNumber.value - 1;

      return {
        currentPageIndex,
        stepRelativeIndex: index - currentPageIndex,
        isCurrentStep: currentPageIndex === index,
      };
    }

    const getPadding = () => {
      const { stepRelativeIndex } = getStepCurrentParameters();

      const paddings = isMobile.value
        ? ["0px 0px 40px", "0px 20px 20px", "0px 40px 0px", "0px 40px 0px"]
        : ["0px 40px", "32px 20px", "64px 0px", "64px 0px"];

      if (stepRelativeIndex < 0) return paddings[0];
      if (stepRelativeIndex > 2) return paddings[3];

      return paddings[stepRelativeIndex];
    };

    const getOpacity = () => {
      const { stepRelativeIndex } = getStepCurrentParameters();
      if (stepRelativeIndex > 2 || stepRelativeIndex < 0) return 0;

      const opacities = [1, 1, 0.4];

      return opacities[stepRelativeIndex];
    };

    const motion = useMotion(document.getElementById(`join-${index}`), {
      initial: {
        y: 0,
        scale: 0,
        padding: getPadding(),
        opacity: getOpacity(),
        zIndex: 10 - index,
      },
    });

    // Element methods
    const hide = (withoutAnimation?: boolean) => {
      if (withoutAnimation) {
        motion.apply({
          y: 400,
          opacity: 0,
          transition: {
            duration: 0,
          },
        });
        return;
      }

      motion.apply({
        y: 400,
        opacity: 0,
        transition: {
          type: "spring",
          damping: 10,
        },
      });
    };

    async function mount() {
      const { stepRelativeIndex } = getStepCurrentParameters();

      // If the step is not in the current page, mount instantly without animation
      if (stepRelativeIndex > 2 || stepRelativeIndex < 0) {
        motion.apply({
          scale: 1,
          zIndex: 10 - index,
          y: stepRelativeIndex < 0 ? 400 : 0,
          padding: getPadding(),
          opacity: getOpacity(),
          transition: {
            duration: 0,
          },
        });
      }
      // Else animate it
      else {
        const delay = 400 - stepRelativeIndex * 150;

        motion.apply({
          scale: 1,
          zIndex: 10 - index,
          y: 0,
          padding: getPadding(),
          opacity: getOpacity(),
          transition: {
            delay,
            type: "spring",
            stiffness: 60,
            damping: 15,
            mass: 2,
          },
        });
      }
    }

    // Handle animation on page change for the this step
    const animate = () => {
      const newParameters = getStepCurrentParameters();

      // If this was last step and the new page is the next one, hide it
      if (newParameters.stepRelativeIndex === -1) {
        hide();
      }
      // Animate new step that should be positioned on the screen
      else if (
        newParameters.stepRelativeIndex <= 2 &&
        newParameters.stepRelativeIndex >= 0
      ) {
        motion.apply({
          y: 0,
          padding: getPadding(),
          opacity: getOpacity(),
          transition: {
            type: "spring",
            stiffness: 60,
            damping: 14,
            mass: 1,
          },
        });
      }
      // Position concluded steps out of the screen
      else if (newParameters.stepRelativeIndex < -1) {
        hide(true);
      }
      // Position distant steps out of the screen
      else if (newParameters.stepRelativeIndex > 2) {
        motion.apply({
          y: 0,
          padding: getPadding(),
          opacity: getOpacity(),
          transition: {
            type: "spring",
            stiffness: 60,
            damping: 14,
            mass: 1,
          },
        });
      }
    };

    return {
      name: step.name,
      motion,
      animate,
      mount,
    };
  });
};

// Mount elements
const mountElements = () => {
  elements.value.forEach((element) => {
    element.mount();
  });

  setTimeout(() => {
    hasBeenMounted.value = true;
  }, 0);
};

// Handle next & back events
const handleNext = () => {
  goToNext();
};

const handleBack = () => {
  goBack();
};
</script>

<style lang="scss" scoped>
.join-level {
  @apply flex h-full w-full flex-grow opacity-0;
}
.join-first-level {
  @apply relative z-50;
}
.join-second-level {
  @apply absolute z-20;
}
.join-third-level {
  @apply absolute z-10;
}
.join-fourth-level {
  @apply absolute z-0;
}
.join-hidden {
  width: 0;
  @apply absolute z-0;
}
</style>
