<template>
  <div class="px-4 pt-[80px] lg:pt-[141px]">
    <JoinTitle
      class="mx-auto mb-[117px] whitespace-pre-wrap md:mb-[54px] md:h-[154px] md:max-w-[956px]"
    >
      <template v-for="(letter, index) in title" :key="index">
        <span
          v-if="letter !== ','"
          v-motion
          class="inline-block origin-bottom"
          :initial="{
            scale: 0,
          }"
          :enter="{
            scale: 1,
            transition: {
              type: 'spring',
              stiffness: 60,
              damping: 7,
              mass: 0.8,
              delay: index * 20,
            },
          }"
          >{{ letter }}</span
        >
        <span v-else><br /></span>
      </template>
    </JoinTitle>
    <JoinSubtitle class="relative mb-[23px] md:mb-[24px]">Email</JoinSubtitle>

    <div
      class="relative mx-auto flex flex-col justify-center pt-4 md:max-w-[527px]"
    >
      <JoinToastPig
        :open="(!isValidEmail || !joinStore.data.email) && !!emailError"
      >
        {{ emailError }}
      </JoinToastPig>
      <!-- reduce 12px from TextInput's bottom to accomodate the error message above on mobile [e.g. pt-4] -->
      <TextInput
        v-model="data.email"
        input-class="bg-[#FEE6EE] w-full"
        placeholder="jane@email.com"
        class="z-10 mx-auto mb-3 flex w-full text-lg leading-[21.6px] md:mb-[40px] md:h-[46px]"
        required
        @keydown.enter="validateAndGoNext"
      />
    </div>

    <div class="mb-[132px] flex justify-center space-x-3">
      <Button
        variant="icon"
        class="border-with-shadow min-h-[50px] min-w-[50px] flex-shrink-0"
        @click="joinStore.goBack"
      >
        <img src="/images/join/email/arrow.png" class="w-[14px]" />
      </Button>
      <JoinButton @click="validateAndGoNext"> Continue </JoinButton>
    </div>

    <div
      class="mx-auto mb-6 mt-[132px] h-[22px] max-w-[307px] text-center font-Aeonik text-xs font-normal leading-[14.4px] -tracking-[0.01em] text-[#16114F] md:mb-[40px] md:mt-[133px] md:max-w-[295px] md:-tracking-[0.03em]"
    >
      <span class="opacity-60"
        >By submitting, you agree to receive marketing emails and our
        <a
          href="https://app.termly.io/document/terms-of-use-for-website/403554a8-9548-481a-9f10-feb1a00b8d7a"
          target="_blank"
          class="underline underline-offset-4"
          >Terms</a
        >
        and
        <a
          href="https://app.termly.io/document/privacy-policy/e5344696-998a-41ba-b8b8-2b2a140d92bd"
          target="_blank"
          class="underline underline-offset-4"
          >Privacy Policy</a
        >. Unsubscribe at any time.</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
const joinStore = useJoinStore();
const { data } = storeToRefs(useJoinStore());

const title = "Before we begin, what’s your email?".split("");

const emailError = ref("");

const isValidEmail = computed(() => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(data.value.email);
});

const emit = defineEmits(["next"]);

const validateAndGoNext = () => {
  if (!isValidEmail.value) {
    emailError.value = "Please enter a valid email address.";
    return;
  }

  emit("next");
};
</script>

<style scoped lang="scss">
.border-with-shadow {
  @apply border border-[#16114F] shadow-[1px_1px_0px_0px_#16114F];
}

.bounce {
  outline: 0;
  border-color: red;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
</style>
